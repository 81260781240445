<template >
  <div
	  :key="hoursComponent"
	  class="colelHours"
  >
      <div
		 v-if="!introShow"
		 class="p-pt-4 p-pr-4 p-pl-4"
	 >
          <div
			:key="index"
			v-for="(day, index) in weekDays"
		>
            <div class="p-grid p-ai-center">
              <div class="p-col-3">
                <div class="box">
                  <h4 class="text--secondary">
                    {{ $t(`colelHours.${day}`) }}:
                  </h4 >
                </div >
              </div >
              <div class="p-col">
                <div class="box">

                  <timePickerResponce
				   :readonly="readonly"
				   :key="isLoading"
				   :timeProp="colelHours[jsonDays[index][0]].slice(0, 5)"
				   v-on:pickTime="pickTime($event, index, true)"
				   :class="getClassChange(index, 0)"

				   :label="$t('colelHours.startHour')"
			   />
                </div >
              </div >
              <div class="p-col">
                <div class="box">
                  <timePickerResponce
				   :readonly="readonly"
				   :key="isLoading"
				   :timeProp="colelHours[jsonDays[index][1]].slice(0, 5)"
				   :class="getClassChange(index, 1)"
				   v-on:pickTime="pickTime($event, index, false)"
				   :label="$t('colelHours.endHour')"
			   />
                </div >
              </div >
            </div >
          </div >
        </div >

    <colel-card
	    v-if="introShow"
	    :title="$t('colelHours.title')"
	    :icon="'mdi-clock-time-two-outline'"
	    :isLoading="isLoading"
    >
      <template v-slot:content>
        <div class="p-pt-4 p-pr-4 p-pl-4">
          <div
			:key="index"
			v-for="(day, index) in weekDays"
		>
            <div class="p-grid p-ai-center">
              <div class="p-col-3">
                <div class="box">
                  <h4 class="text--secondary">
                    {{ $t(`colelHours.${day}`) }}:
                  </h4 >
                </div >
              </div >
              <div class="p-col">
                <div class="box">
                  <timePickerResponce
				   :readonly="readonly"
				   :key="isLoading"
				   :timeProp="colelHours[jsonDays[index][0]].slice(0, 5)"
				   :class="{'change': changes[jsonDays[index][0]]}"
				   v-on:pickTime="pickTime($event, index, true)"
				   :label="$t('colelHours.startHour')"
			   />
                </div >

              </div >
              <div class="p-col">
                <div class="box">
                  <timePickerResponce
				   :readonly="readonly"
				   :key="isLoading"
				   :class="{'change': changes[jsonDays[index][1]]}"
				   :timeProp="colelHours[jsonDays[index][1]].slice(0, 5)"
				   v-on:pickTime="pickTime($event, index, false)"
				   :label="$t('colelHours.endHour')"
			   />
                </div >
              </div >
			 <!---->
            </div >
          </div >
        </div >
		<!--         <v-card-actions v-if="!introShow">-->
		<!--                <v-spacer></v-spacer>-->
		<!--                <v-btn color="blue darken-1" text @click="cancel">-->
		<!--                  {{ $t("form.cancel") }}-->
		<!--                </v-btn>-->
		<!--                <v-btn-->
		<!--                  type="submit"-->
		<!--                  :disabled="invalid"-->
		<!--                  color="blue darken-1"-->
		<!--                  text-->
		<!--                  @click="post"-->
		<!--                  >{{ $t("form.ok") }}</v-btn-->
		<!--                >-->
		<!--              </v-card-actions>-->
      </template >
    </colel-card >
  </div >
</template >

<script >
import timePickerResponce from "../../../components/timePickerResponce.vue";
import {mapState} from "vuex";

const defaultHours = {
  sunday_start: "00:00:00",
  sunday_end: "00:00:00",
  monday_start: "00:00:00",
  monday_end: "00:00:00",
  tuesday_start: "00:00:00",
  tuesday_end: "00:00:00",
  wednesday_start: "00:00:00",
  wednesday_end: "00:00:00",
  thursday_start: "00:00:00",
  thursday_end: "00:00:00",
  friday_start: "00:00:00",
  friday_end: "00:00:00",
  version: 1
};

export default {
  name: "colelHours",
  props: {
    deepCopy: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    changes: {
      type: Object,
      default: () => {return {}}
    },
    colelProp: Object,
    introShow: {
      type: Boolean,
      default: false
    }
  },
  components: {
    timePickerResponce
  },
  data() {
    return {
      hoursComponent: 0,
      colelReceive: this.colelProp,
      isLoading: false,
      colelHours: {...defaultHours},
      newHours: null,
      weekDays: ["day1", "day2", "day3", "day4", "day5", "day6"],
      jsonDays: [
        ["sunday_start", "sunday_end"],
        ["monday_start", "monday_end"],
        ["tuesday_start", "tuesday_end"],
        ["wednesday_start", "wednesday_end"],
        ["thursday_start", "thursday_end"],
        ["friday_start", "friday_end"]
      ]
    };
  },
  created() {
    if (this.colelProp?.colel_details) {
      if (this.deepCopy){
        this.colelHours = {...this.colelProp.colel_details.hours};
        this.newHours = {...this.colelProp.colel_details.hours};
      } else {
        this.colelHours = this.colelProp.colel_details.hours;
        this.newHours = this.colelProp.colel_details.hours;
      }

    } else {
      this.colelHours = {...defaultHours};
      this.newHours = {...defaultHours};

    }
  },

  watch: {
    colelProp(newColel) {
      if (newColel.colel_details) {
        if (this.deepCopy){
          this.colelHours = {...newColel.colel_details.hours};
          this.newHours = {...newColel.colel_details.hours};
        } else {
          this.colelHours = newColel.colel_details.hours;
          this.newHours = newColel.colel_details.hours;
        }

      } else {
        this.colelHours = {...defaultHours};
        this.newHours = {...defaultHours};

      }
    }
  },
  methods: {
    getClassChange(index, startOrEnd) {
      return this.changes?.[this.jsonDays[index][startOrEnd]] ? 'change' : ''
    },

    pickTime(time, day, isStart) {
      if (time.length === 5) {
        time += ':00'
      }
      this.newHours[this.jsonDays[day][isStart ? 0 : 1]] = time
      this.$emit("updateHours", this.newHours);
      this.$emit("updateHour", this.jsonDays[day][isStart ? 0 : 1]);
    },
    async post() {
      this.colelReceive.hours = this.newHours
      const update = {
        id: this.colelReceive.id,
        hours: this.newHours
      }
      this.$emit("newHours", this.newHours)
      this.$emit("close")
    },
    cancel() {
      this.hoursComponent++
      this.$emit("close")
    }

  }
};
</script >

<style scoped>
>>> .change input {

    color: red !important;
}


</style >
