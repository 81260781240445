<template>
  <div>
    <div  class="d-sm-none" >
    <v-dialog
          

      ref="dialog"
      v-model="modal"
      :return-value.sync="time"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          :label="label"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-on:change="ok"
        ampm-in-title
        format="24hr"
        v-if="modal"
        scrollable
        v-model="time"
        full-width
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false">
          {{ $t(`timePickerDialog.cancel`) }}
        </v-btn>
        <v-btn text color="primary">
          {{ $t(`timePickerDialog.ok`) }}
        </v-btn>
      </v-time-picker>
    </v-dialog>
</div>
 <div  class="d-none d-sm-flex">
    <v-menu
   
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-if="!readonly"
            v-model="time"
            :label="label"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
		  <v-text-field v-else
            v-model="time"
            :label="label"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
          ></v-text-field>
        </template>
        <v-time-picker
        v-on:change="ok"

          v-if="menu"
          v-model="time"
          full-width
          @click:minute="ok(time)"
        ></v-time-picker>
      </v-menu>
</div>

  </div>
</template>

<script>
export default {
  name: "timePickerResponce",
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    label: String,
    timeProp: String,
  },
  mounted() {
    this.time = this.timeProp;
  },
  data() {
    return {
      time: null,
      modal: false,
      menu: false
    };
  },
    watch: {
    timeProp(newProp) {
      this.time = newProp;
    },
  },
  methods: {
    ok(time) {
      this.$emit("pickTime", time);
      this.$refs.dialog.save(time);
      this.$refs.menu.save(time);
      this.menu = false
    },
  },
};
</script>
